export const menueList = [
    // { label: "单个执行测试", value: "1", children: [], path: "/pages/testPage" },
    { label: "执行测试", icon: 'el-icon-s-marketing', value: "1", children: [], path: "/pages/execute" },
    // { label: "应用服务器编码", icon: 'el-icon-s-platform', value: "2", children: [], path: "/pages/applicationServer" },
    { label: "服务器标准", icon: 'el-icon-s-help', value: "3", children: [], path: "/pages/serverCode" },
    // { label: "标准表路径", icon: 'el-icon-s-flag', value: "4", children: [], path: "/pages/standardPath" },
    { label: "对接标准设置", icon: 'el-icon-menu', value: "5", children: [], path: "/pages/standard" },
    { label: "对接内容设置", icon: 'el-icon-s-data', value: "6", children: [], path: "/pages/docking" },

    {
        label: "对接转换设置", 
        icon: 'el-icon-s-management',
        value: "7", 
        children: [
            { label: '附件编号转换设置', value: "7.1", path: "/pages/annex" },
            { label: '数据字典转换设置', value: "7.2", path: "/pages/dict" },
        ], 
        path: ""
    },
    {
        label: "对接参数设置", 
        icon: 'el-icon-s-open', 
        value: "8", 
        children: [
            { label: '监管对接序号', value: '8.1', path: "/pages/regulatory" },
            { label: '对接方法', value: '8.2', path: "/pages/external" },
            { label: '平台编码', value: '8.3', path: "/pages/platform-code" },
        ], 
        path: ""
    },
]