<template>
    <div class="main">
      <div class="nav-bar">
        <el-menu :default-active="activeIndex"  class="el-menu-vertical-demo" @select="selectMenu" >
          <template v-for="(item, index) in MenuS">
            <el-menu-item :key="index" :index="item.value" v-if="item.children.length <= 0" @click="curMenu(item)">
              <i :class="item.icon"></i>
              <span>{{ item.label }}</span>
            </el-menu-item>
            <el-submenu v-if="item.children.length > 0" :key="index" :index="item.value">
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{ item.label }}</span>
              </template>
              <el-menu-item v-for="(it, inx) in item.children" :key="inx" :index="it.value" @click="curMenu(it)">
                <span>{{ it.label }}</span>
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </div>
    </div>
  </template>
  <script>
  import { setStore, getStore } from "@/utils/store";
  import {menueList} from './menueList'
  export default {
    components: {},
    props: {},
    data() {
      return {
        activeIndex: "",
        logoBlue: require("@/assets/images/logo/logo_blue.png"),
        MenuS: [],
      };
    },
    computed: {},
    watch: {
    },
    created() {
      this.MenuS=menueList
      let active = getStore({ name: "activeMenu" });
      if (active) {
        this.activeIndex = active.value;
      } else {
        this.activeIndex = this.MenuS[0].value;
        setStore({
          name: "activeMenu",
          content: this.MenuS[0],
          type: "session",
        });
      }
    },
    mounted() { },
    methods: {
      curMenu(item) {
        setStore({ name: "activeMenu", content: item, type: "session" });
        this.$router.push({ path: item.path });
      },
      selectMenu(index){
        this.activeIndex = index;
      },
    },
  };
  </script>
  <style scoped lang='scss'>
  .main {
    width: 100%;
    height: 100%;
  
    .nav-bar {
      ::v-deep .el-menu {
        border: none
      }
  
      ::v-deep .el-submenu__title,
      ::v-deep .el-menu-item {
        display: flex;
        align-items: center;
        width: 158px;
      }
      ::v-deep .el-submenu__icon-arrow{
        right: 5px;
      }
    }
  }
  </style>