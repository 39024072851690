import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins/vant'
import 'vant/lib/index.css';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/index.scss'


Vue.use(ElementUI)
Vue.config.productionTip = false


window.addEventListener('popstate', function() {
  history.pushState(null, null, document.URL)
 })
 


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
