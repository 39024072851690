<template>
  <div class="page-wrapper">
    <div class="header-container">
      <img class="logo" :src="logoBlue" alt="" />
      <div class="user-center">
        <UserCenter></UserCenter>
      </div>
    </div>
    <div class="page-container">
      <el-card class="box-card page-component__menus">
        <NavBar></NavBar>
      </el-card>
      <el-card class="box-card page-component__content">
        <router-view></router-view>
      </el-card>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/layout/navBar/index.vue";
import UserCenter from "@/components/layout/header/userCenter.vue";
export default {
  components: { NavBar, UserCenter },
  props: {},
  data() {
    return {
      logoBlue: require("@/assets/images/logo/logo_blue.png"),
    };
  },
  created() { },
  mounted() { },
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style scoped lang='scss'>
.page-wrapper {
  width: 100%;
  height: 100%;

  .header-container {
    width: 100%;
    height: 58px;
    padding: 0 20px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      height: 80%;
      width: 130px;
    }

    .user-center {
      width: 600px;
      height: 100%;
    }
  }

  .page-container {
    display: flex;
    height: calc(100% - 98px);
    margin: 20px 15px;

    .page-component__menus,
    .page-component__content {
      height: 100%;
      border-radius: 10px;
      box-sizing: border-box;
    }

    .page-component__menus {
      width: 200px;
      margin-right: 20px;
    }

    .page-component__content {
      flex: 1;
      ::v-deep .el-card__body{
        height: 100%;
      }
    }
  }
}
</style>