
import company from './company'
import page404 from '@/components/error/404'

export default [
  ...company,
  {
    hidden: true,
    path: '*',
    component: page404
  }
]