import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { getStore } from '@/utils/store'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const Router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => {
    // console.log('scrollBehavior')
    // history.pushState(null, null, document.URL)
   }
})

Router.beforeEach((to, from, next) => {
  // console.log('路由守卫',to,from)
  let token= getStore({name:'token'})
  if(to.path!=='/'&& !token){
    next({path:'/'})
  }else {
    // if(to.path==='/'&&token){
    //   next(false)
    // }else{
    //   next()
    // }
    next()

  }
})

export default Router
