import Layout from '@/components/layout'
export default [
  // 首页
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/login/index.vue')
  },
  {
    path: '/pages',
    name: 'pages',
    redirect: '/pages/execute',
    component: Layout,
    children: [
      {
        hiddenByShowTree: true,
        path: 'execute',
        title: '对接执行表',
        name: 'execute',
        component: () => import(/* webpackChunkName: "execute" */ '@/pages/execute/index.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'applicationServer',
        title: '应用服务器编码',
        name: 'execute',
        component: () => import(/* webpackChunkName: "execute" */ '@/pages/applicationServer/index.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'serverCode',
        title: '服务器编码',
        name: 'execute',
        component: () => import(/* webpackChunkName: "execute" */ '@/pages/serverCode/index.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'standardPath',
        title: '标准表路径',
        name: 'execute',
        component: () => import(/* webpackChunkName: "execute" */ '@/pages/standardPath/index.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'docking',
        title: '对接测试',
        name: 'docking',
        component: () => import(/* webpackChunkName: "docking" */ '@/pages/docking/index.vue')
      },

      {
        hiddenByShowTree: true,
        path: 'standard',
        title: '对接标准设置',
        name: 'standard',
        component: () => import(/* webpackChunkName: "standard" */ '@/pages/standard/index.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'annex',
        title: '附件编号转换表',
        name: 'annex',
        component: () => import(/* webpackChunkName: "convert" */ '@/pages/convert/annex.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'dict',
        title: '数据字典转换表',
        name: 'dict',
        component: () => import(/* webpackChunkName: "convert" */ '@/pages/convert/dict.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'regulatory',
        title: '监管对接序号表',
        name: 'regulatory',
        component: () => import(/* webpackChunkName: "arguments" */ '@/pages/arguments/regulatoryDocking.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'external',
        title: '外部对接参数表',
        name: 'external',
        component: () => import(/* webpackChunkName: "arguments" */ '@/pages/arguments/externalDocking.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'platform-code',
        title: '平台编码表',
        name: 'platform-code',
        component: () => import(/* webpackChunkName: "arguments" */ '@/pages/arguments/platformCode.vue')
      },
      // {
      //   hiddenByShowTree: true,
      //   path: 'testPage',
      //   title:'其他',
      //   name: 'testPage',
      //   component: () => import(/* webpackChunkName: "testPage" */ '@/pages/testPage/index.vue')
      // },
    ]
  },

]