<template>
  <div class="main">
    <el-popover placement="bottom" trigger="click" v-model="visible" :append-to-body="false">
      <div class="toolbar_container">
        <el-menu default-active="1" class="el-menu-vertical-demo">
          <el-menu-item v-for="(bar, index) in toolbars" :key="index" :index="bar.value" @click="curMenu(bar)">
            <span>{{ bar.title }}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <el-avatar slot="reference" size="large"
        src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
    </el-popover>

    <!-- <p>郑刚</p>
      <img :src="logoBlue" alt=""> -->
  </div>
</template>
<script>
import {removeStore} from '@/utils/store'
export default {
  components: {},
  props: {},
  data() {
    return {
      visible: false,
      logoBlue: require("@/assets/images/logo/logo_blue.png"),
      toolbars: [
        { title: '个人中心', value: '1' },
        { title: '退出登录', value: '2' },
      ]
    };
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {
    curMenu(item){
      switch (item.title){
        case '个人中心': 
        break
        case '退出登录': 
        removeStore({ name: 'token' })
        removeStore({ name: 'activeIndex' })
        this.$router.push({ path: "/"});
        break
      }

    }
  },
};
</script>
<style scoped lang='scss'>
.main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;

  .toolbar_container {
    ::v-deep .el-menu {
      border: none
    }

    ::v-deep .el-menu-item {
      text-align: center;
      height: 36px;
      line-height: 36px;
    }
  }

}
</style>