<template>
  <div class="err-404">
    <img src="~@/assets/images/error.jpg" />
    <div>
      <el-button type="primary" @click="toHome">返回首页</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Err404',
  methods: {
    toHome() {
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss" scoped>
.err-404 {
  height: 100%;
  text-align: center;
  img {
    margin: 80px 0 30px 0;
    width: 30%;
  }
}
</style>
